import React from 'react';
// import { Link } from 'gatsby';
import Layout from '../layouts/de';
import OtherInvestments from '../components/OtherInvestments';
import iconDownBlue from '../assets/images/icons/icon-down-blue.svg';
import shareFacebook from '../assets/images/icons/icon-share-facebook.svg';
import shareLinkedin from '../assets/images/icons/icon-share-linkedin.svg';
import shareTwitter from '../assets/images/icons/icon-share-twitter.svg';
import Scroll from './../components/Scroll';
import axios from 'axios';
import noImage from '../assets/images/no-image.png'
import showdown from 'showdown'
import ApolloClient from 'apollo-boost';
import { gql } from "apollo-boost";
import { navigate } from 'gatsby';
import Spinner from '../components/Spinner';

import { openUrl } from '../util/url';

export default class newsDetailPage extends React.Component {

  client = new ApolloClient({
    uri: `${process.env.GATSBY_API_URL}/graphql`,
  });

  apiEndPoint = process.env.GATSBY_API_URL;

  state = {};

  slug = '';

  news = {};

  url = '';

  constructor(props) {
    super(props)
    this.state = {
      related_portfolio: null,
      allCompanies: [],
      loading: false
    }
    this.slug = props.location.search.replace('?', '');
    this.url = props.location.href;
  }

  componentDidMount() {
    this.getAllNews()
  }

  loadCompanies() {
    return this.client.query({
      fetchPolicy: 'no-cache',
      query: gql`
        {
          portfoliodes (
            where: {
              active: true
            }
          ) {
            client
            id
            business
          }
        }
      `
    }).then(({ data }) => {
      const companies = data.portfoliodes;
      this.setState({allCompanies: companies});
    });
  }

  loadArticle() {
    return new Promise((resolve, reject) => {
      this.client.query({
        fetchPolicy: 'no-cache',
        query: gql`
          {
            newsdes (
              limit: 1,
              where: {
                slug: "${this.slug}",
                active: true,
              }
            ) {
              id
              client
              summary
              source
              img_heading
              published
              article_content
              active
              slug
              company_related
              created_at
              updated_at
              heading_image {
                url
              }
              source_url
            }
          }
        `
      }).then((response) => {
        const { data } = response;
        const { newsdes } = data;

        if (newsdes.length > 0) {
          resolve(newsdes[0]);
        } else {
          reject("No news with such slug");
        }
      }).catch(err => reject(err));
    });
  }

  loadRelatedCompanies() {
    const { details } = this.state;
    let query = gql`
    {
        portfoliodes (
            where: {
              active: true
            }
          ) {
            slug
            company_name
            aquired
            company_logo {
                url
            }
            portfolio_image {
                url
            }
            Sold
            category
        }
    }`;

    if (details.company_related && details.company_related !== '--') {
      query = gql`
      {
          portfoliodes(
              where: {
                  id: ${details.company_related},
                  active: true
              }
          ) {
              slug
              company_name
              aquired
              company_logo {
                  url
              }
              portfolio_image {
                  url
              }
              Sold
              category
          }
      }`;
    }

    this.client.query({
      fetchPolicy: 'no-cache',
      query
    }).then(
      (response) => {
        const { portfoliodes: portfolios } = response.data;
        let relatedPortfolio = null;
        if (portfolios.length === 1) {
          relatedPortfolio = portfolios[0];
        } else if (portfolios.length > 1) {
          const randomPortfolioIndex = Math.floor(Math.random() * portfolios.length)
          relatedPortfolio = portfolios[randomPortfolioIndex];
        }

        this.setState({
          related_portfolio: relatedPortfolio
        });
    });
  }

  /**
   * Get all news from response.
   */
  async getAllNews() {
    if (typeof window !== 'undefined') {
      this.setState({
        loading: true
      });
      this.loadArticle()
        .then(article => {
          this.setState({
            details: article,
            loading: false
          }, () => {
            this.loadCompanies();
            this.loadRelatedCompanies();
          });
        }).catch((err) => {
          console.log("ERR", err);
          navigate('/404');
        });
    }
  }
  
  /**
   * It sets news image container content. 
   */
  newsImage() {
    let news = this.state.details;
    let options = {month: 'long', day: '2-digit', year: 'numeric'};
    return ( 
      <div className="news-detail-image" id="header">
        <img className="medium-darkened" src={(news && news.heading_image !== undefined && news.heading_image !== null) ? `${this.apiEndPoint}/${news.heading_image.url}` : noImage} alt="Active Capital Company and management invest in SchahlLED"/>
        <div className="news-image-info">
          {news && <h1>{news.img_heading}</h1>}
          {news && <span className="published-info cursor--pointer" onClick={() => openUrl(news.source_url)}>{news.source || 'Pressemitteilung'}, <span className="first-uppercase-letter">{new Intl.DateTimeFormat('de-DE', options).format(new Date(news.published))}</span></span>}
        </div>
      </div>
      )
  }

  /**
   * It sets news heading content.
   */
  newsHeading() {
    let news = this.state.details;

    const { allCompanies } = this.state;
    let about = null;
    let clientName = null;
    if (news && news.client && news.client !== '--' && allCompanies) {
      const client = allCompanies.find(c => c.id === news.client);
      if (client) {
        about = client.business;
        clientName = client.client;
      }
    }

    return (
      <div className="col-lg-6 float-left news-content-wrapper">
          <div className="head-client">
            <p>Unternehmen</p>
            {clientName && <h4 className="custom-h4">{clientName}</h4>}
          </div>
          <div className="head-business">
            <p>Tätigkeit</p>
            {about && <h4 className="custom-h4">{about}</h4>}
          </div>
          <div className="head-source">
            <p>Quelle</p>
            {news && <h4 className="custom-h4 cursor--pointer" onClick={() => openUrl(news.source_url)}>{news.source || 'Pressemitteilung'}</h4>}
          </div>
          <Scroll type="id" element="news-content">
            <a href="?scroll-to=header" className="head-link">
              <h5 className="acquisition-text"><img src={iconDownBlue} alt="Acquisition"/> Mehr</h5>
            </a>
        </Scroll>
      </div>
    )
  }

  /**
   * It sets news content.
   */
  newsContent() {
    let news = this.state.details;
    if (news === undefined || news === null) {
      return
    }
    const converter = new showdown.Converter();
    const text = converter.makeHtml(news.article_content);
    return (
      <div id="news-content" className="custom-editor-styles news-custom-editor-styles" dangerouslySetInnerHTML={{ __html: text }} />
  
    )
  }

  /**
   * It sets footer content.
   */
  footerContent() {
    let news = this.state.details;
    if (news === undefined || news === null) {
      return
    }

    const { allCompanies } = this.state;
    let clientName = null;
    if (news.client && news.client !== '--' && allCompanies) {
      const client = allCompanies.find(c => c.id === news.client);
      if (client) {
        clientName = client.client;
      }
    }

    return (
      <div>
        {clientName && <div className="client-block">
          <span className="gray-label">Unternehmen</span>
          <span className="news-client-name">{clientName}</span>
        </div>}
        <div className="source-block">
          <span className="gray-label">Quelle</span>
          <span className="news-client-name cursor--pointer" onClick={() => openUrl(news.source_url)}>{news.source || 'Pressemitteilung'}</span>
        </div>
        <div className="clearfix"></div>
      </div>
    )

  }

  /**
   * It sets social content.
   */
  socialShare() {
    return (
      <div className="share-block bottom-spacing">
        <span className="gray-label">Teilen</span>
        <div className="social-icons">
          <a href={'https://www.linkedin.com/shareArticle?mini=true&url=' + this.url} target="_blank" ><img src={shareLinkedin} alt="LinkedIn share" /></a>
        </div>
      </div>
    )
  }

  render() {
    const { loading } = this.state;

    return(
      <Layout>
        <Spinner show={loading} />
        <div className="news-details-wrapper">
          <div className="col-lg-6 float-left no-left-padding">
            {this.newsImage()}
          </div>
            {this.newsHeading()}
          <div className="clearfix"></div>
          <section className="narrow-wrapper custom-news-narrow-wrapper col-sm-12">
            {this.newsContent()}            
            <span className="horizontal-line gray-background no-side-margin"></span>
            {this.footerContent()}
            <div className="clearfix"></div>
            {this.socialShare()}
            <div className="clearfix"></div>
            <div className="narrow-wrapper col-sm-12">
              <OtherInvestments data={this.state.related_portfolio} locale={'de-DE'}/>
            </div>
          </section>
          <Scroll type="id" element="header">
            <div className="back-to-top-wrapper">
                <a href="?scroll-to=header">
                    <i className="fas fa-angle-double-up"></i>
                    <br />
                    <span className="back-to-top">Zurück zu Start</span>
                </a>
            </div>

        </Scroll>
        </div>
      </Layout>
    );
  }

}